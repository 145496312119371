@font-face {
  font-family: LibreFranklin;
  src: url("librefranklin-medium-webfont.bdcd11d7.woff2") format("woff2"), url("librefranklin-medium-webfont.365ea7d2.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: BryantPro;
  src: url("BryantPro-Regular.fb49c29d.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Vibur;
  src: url("Vibur-Regular.53d02f50.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  height: 100vh;
  margin: 0;
  font-family: Roboto, sans-serif;
}

#app {
  width: 100%;
}

#contenedor-login {
  background: linear-gradient(162deg, #b067c4 0%, #6f569e 35% 65%, #8b2377 100%);
  border-radius: 2rem;
  width: 70%;
  margin-top: 8rem;
  margin-left: 50%;
  display: flex;
  transform: translateX(-50%);
  box-shadow: 10px 10px 5px 1px #00000014;
}

#contenedor-login .contenedor-logo {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  top: 8rem;
}

#contenedor-login .contenedor-logo #logo-universo {
  width: 225px;
}

#contenedor-login .contenedor-datos {
  background-color: #fff;
  border-radius: 2rem;
  flex-direction: column;
  width: 50%;
  height: 100%;
  display: flex;
}

#contenedor-login .contenedor-datos .formulario-login {
  width: 100%;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-cabecera {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 30%;
  margin-top: 2rem;
  display: flex;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-cabecera .login-form-heading {
  color: #242424;
  margin-bottom: 2rem;
  font-size: 15pt;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-cabecera .titulo-login {
  color: #6f569e;
  margin-bottom: .5rem;
  font-size: 3rem;
  font-weight: bold;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-formulario {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input.form-control {
  text-align: center;
  color: #464a4c;
  background-color: #efefef;
  border: none;
  border-radius: 5rem;
  width: 75%;
  margin-bottom: 2rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-formulario textarea:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="text"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="password"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="datetime"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="datetime-local"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="date"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="month"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="time"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="week"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="number"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="email"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="url"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="search"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="tel"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario input[type="color"]:focus, #contenedor-login .contenedor-datos .formulario-login .contenedor-formulario .uneditable-input:focus {
  border: 1px solid #6f569e;
  outline: 0;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-reset-contraseña {
  text-align: end;
  color: #242424;
  margin-right: 3rem;
  font-size: .75rem;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-reset-contraseña a {
  color: #000;
  text-decoration: none;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-btn {
  justify-content: center;
  display: flex;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-btn .btn-acceder {
  color: #fff;
  background-color: #fe9015;
  border: 0;
  border-radius: 5rem;
  width: 7rem;
  height: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: .8rem;
  font-weight: bold;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-logo-polygon {
  justify-content: center;
  display: flex;
}

#contenedor-login .contenedor-datos .formulario-login .contenedor-logo-polygon #logo-polygon {
  width: 96px;
  margin: 24px;
}

#contenedor-login textarea:focus, #contenedor-login input[type="text"]:focus, #contenedor-login input[type="password"]:focus, #contenedor-login input[type="datetime"]:focus, #contenedor-login input[type="datetime-local"]:focus, #contenedor-login input[type="date"]:focus, #contenedor-login input[type="month"]:focus, #contenedor-login input[type="time"]:focus, #contenedor-login input[type="week"]:focus, #contenedor-login input[type="number"]:focus, #contenedor-login input[type="email"]:focus, #contenedor-login input[type="url"]:focus, #contenedor-login input[type="search"]:focus, #contenedor-login input[type="tel"]:focus, #contenedor-login input[type="color"]:focus, #contenedor-login .uneditable-input:focus {
  border: 1px solid #6f569e;
  outline: 0;
}

.menu-docente {
  align-items: center;
  height: 6rem;
  font-size: .95rem;
  display: flex;
}

.menu-docente .logoUniverso {
  background-image: url("logo_universo.10238e4a.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16%;
  height: 5rem;
  margin-left: 12rem;
}

.menu-docente .contenedorEnlaces {
  justify-content: center;
  width: 40%;
  display: flex;
}

.menu-docente .contenedorEnlaces a {
  color: #70569f;
  border: 2px solid #70569f;
  border-radius: .3rem;
  margin: 1rem;
  padding: .4rem .6rem;
  text-decoration: none;
}

.menu-docente .contenedorEnlaces a.active {
  color: #fff;
  background-color: #70569f;
  border-bottom: 2px solid #70569f;
  font-weight: bold;
}

.menu-docente .contenedorEnlaces .contenedorParaEscribir {
  margin: 1rem;
}

.contenedorTextoBienvenida {
  text-align: justify;
  width: 60%;
  margin-left: 50%;
  padding: 2rem;
  font-family: BryantPro;
  font-size: 1.5rem;
  transform: translateX(-50%);
}

.contenedorTextoBienvenida div {
  margin-bottom: 1rem;
}

.contenedorTextoBienvenida h1.titulo {
  color: #6a5ba6;
  font-family: Vibur;
  font-size: 3rem;
  font-weight: normal;
}

.contenedorTextoBienvenida a {
  color: #000;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;
}

#panelEscribiendo {
  width: 100vw;
  height: 100%;
  margin: 0;
}

#panelEscribiendo #app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#panelEscribiendo #popUpPizarraParaEscribir {
  text-align: center;
  background-color: #0009;
  width: 100%;
  height: 100%;
}

#panelEscribiendo div.contenedorMenuLetras {
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 1rem;
  display: flex;
}

#panelEscribiendo div.contenedorMenuLetras div.planetaMiniatura {
  aspect-ratio: 16 / 9;
  box-sizing: border-box;
  width: 12%;
}

#panelEscribiendo div.contenedorMenuLetras .planetaMiniatura img {
  width: 100%;
}

#panelEscribiendo .contenedorMenu {
  flex-wrap: wrap;
  justify-content: space-around;
  height: 80%;
  padding: 1rem;
  display: flex;
  overflow-y: scroll;
}

#panelEscribiendo .miniatura {
  aspect-ratio: 16 / 9;
  box-sizing: border-box;
  width: 25%;
  padding: 2rem;
}

#panelEscribiendo .miniatura img {
  border: 1px solid #000;
  width: 100%;
}

#panelEscribiendo .fondoModales {
  z-index: 3;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#panelEscribiendo #closeWidget {
  position: absolute;
  top: -15px;
  right: -15px;
}

#panelEscribiendo img#closeWidget {
  width: 33px;
  height: 33px;
}

#panelEscribiendo .seleccionablePalabras {
  aspect-ratio: 16 / 9;
  width: 75%;
  margin-bottom: 3rem;
  position: relative;
}

#panelEscribiendo .contenedorPizarraYBotonera {
  aspect-ratio: 4 / 3;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: auto;
  position: relative;
}

#panelEscribiendo .contenedorPizarra {
  width: 100%;
  height: 100%;
  position: absolute;
}

#panelEscribiendo div.pizarra {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#panelEscribiendo canvas {
  touch-action: none;
}

#panelEscribiendo div.botoneraPizarra {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  display: flex;
  position: absolute;
  bottom: -75px;
}

#panelEscribiendo div.boton {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin-left: 1rem;
}

#panelEscribiendo div.boton.seleccionado {
  transform: scale(1.2);
}

#panelEscribiendo div.boton.goma {
  background-color: gray;
  background-image: url("goma.c018b15e.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  margin-left: 0;
}

#panelEscribiendo .papeleraPizarra {
  width: 2rem;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

#pantallasJuego {
  height: 100%;
}

#pantallasJuego .contenedorJuegos {
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2rem;
  display: flex;
}

#pantallasJuego .contenedorJuegos a {
  text-decoration: none;
}

#pantallasJuego .contenedorJuegos .tituloMemory {
  background-color: #6f559f;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 2rem;
  padding: .5rem;
  display: flex;
}

#pantallasJuego .contenedorJuegos .tituloMemory span {
  color: #fff;
}
/*# sourceMappingURL=index.65ece2c9.css.map */
