@font-face {
	font-family: "LibreFranklin";
	src: url("../../fonts/librefranklin-medium-webfont.woff2") format("woff2"),
		url("../../fonts/librefranklin-medium-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "BryantPro";
	src: url("../../fonts/Bryant Pro/BryantPro-Regular.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Vibur";
	src: url("../../fonts/Vibur-Regular.ttf") format("ttf");
	font-weight: normal;
	font-style: normal;
}

body {
	/* width: 100vw; */
	height: 100vh;
	margin: 0;
	/* font-family: "LibreFranklin"; */
	font-family: "Roboto", sans-serif;
}

#app {
	width: 100%;
}

/* Pantalla de login */
#contenedor-login {
	background: linear-gradient(
		162deg,
		#b067c4 0%,
		#6f569e 35% 65%,
		#8b2377 100%
	);
	border-radius: 2rem;
	/* width: 40%; 
	width: 50%;*/
	width: 70%;
	margin-top: 8rem;
	margin-left: 50%;
	transform: translateX(-50%);
	display: flex;
	box-shadow: 10px 10px 5px 1px #00000014;

	.contenedor-logo {
		height: 100%;
		width: 50%;
		position: relative;
		top: 8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		#logo-universo {
			width: 225px;
		}
	}

	.contenedor-datos {
		background-color: white;
		height: 100%;
		width: 50%;
		border-radius: 2rem;
		display: flex;
		flex-direction: column;

		.formulario-login {
			width: 100%;

			.contenedor-cabecera {
				height: 30%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
				margin-top: 2rem;

				.login-form-heading {
					color: #242424;
					font-size: 15pt;
					margin-bottom: 2rem;
				}

				.titulo-login {
					color: #6f569e;
					font-size: 3rem;
					font-weight: bold;
					margin-bottom: 0.5rem;
				}
			}

			.contenedor-formulario {
				display: flex;
				flex-direction: column;
				align-items: center;

				input.form-control {
					width: 75%;
					border-radius: 5rem;
					background-color: #efefef;
					text-align: center;
					margin-bottom: 2rem;
					padding: 0.5rem 0.75rem;
					font-size: 1rem;
					line-height: 1.25;
					color: #464a4c;
					border: none;
				}

				textarea:focus,
				input[type="text"]:focus,
				input[type="password"]:focus,
				input[type="datetime"]:focus,
				input[type="datetime-local"]:focus,
				input[type="date"]:focus,
				input[type="month"]:focus,
				input[type="time"]:focus,
				input[type="week"]:focus,
				input[type="number"]:focus,
				input[type="email"]:focus,
				input[type="url"]:focus,
				input[type="search"]:focus,
				input[type="tel"]:focus,
				input[type="color"]:focus,
				.uneditable-input:focus {
					border: 1px solid #6f569e;
					outline: 0 none;
				}
			}

			.contenedor-reset-contraseña {
				text-align: end;
				color: #242424;
				font-size: 0.75rem;
				margin-right: 3rem;

				a {
					text-decoration: none;
					color: black;
				}
			}

			.contenedor-btn {
				display: flex;
				justify-content: center;

				.btn-acceder {
					width: 7rem;
					height: 2rem;
					border-radius: 5rem;
					background-color: #fe9015;
					border: 0;
					color: white;
					font-weight: bold;
					margin-top: 1rem;
					margin-bottom: 1rem;
					font-size: 0.8rem;
				}
			}

			.contenedor-logo-polygon {
				display: flex;
				justify-content: center;

				#logo-polygon {
					width: 96px;
					margin: 24px;
				}
			}
		}
	}

	textarea:focus,
	input[type="text"]:focus,
	input[type="password"]:focus,
	input[type="datetime"]:focus,
	input[type="datetime-local"]:focus,
	input[type="date"]:focus,
	input[type="month"]:focus,
	input[type="time"]:focus,
	input[type="week"]:focus,
	input[type="number"]:focus,
	input[type="email"]:focus,
	input[type="url"]:focus,
	input[type="search"]:focus,
	input[type="tel"]:focus,
	input[type="color"]:focus,
	.uneditable-input:focus {
		border: 1px solid #6f569e;
		outline: 0 none;
	}
}

/* Apartado del menu */
.menu-docente {
	height: 6rem;
	display: flex;
	align-items: center;
	font-size: 0.95rem;

	.logoUniverso {
		width: 16%;
		height: 5rem;
		margin-left: 12rem;
		background-image: url("../img/logo_universo.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.contenedorEnlaces {
		display: flex;
		justify-content: center;
		width: 40%;

		a {
			text-decoration: none;
			color: #70569f;
			border: solid 2px #70569f;
			padding: 0.4rem 0.6rem;
			border-radius: 0.3rem;
			margin: 1rem;

			&.active {
				border-bottom: 2px #70569f solid;
				background-color: #70569f;
				color: white;
				font-weight: bold;
			}
		}

		.contenedorParaEscribir {
			margin: 1rem;
		}
	}
}

/* Apartado texto bienvenida */
.contenedorTextoBienvenida {
	width: 60%;
	margin-left: 50%;
	transform: translateX(-50%);
	padding: 2rem;
	font-size: 1.5rem;
	text-align: justify;
	font-family: "BryantPro";

	div {
		margin-bottom: 1rem;
	}

	h1.titulo {
		color: #6a5ba6;
		font-size: 3rem;
		font-family: "Vibur";
		font-weight: normal;
	}

	a {
		color: black;
		font-weight: bold;
		cursor: pointer;
		white-space: nowrap;
	}
}

/* Pantalla para escribir */
#panelEscribiendo {
	width: 100vw;
	height: 100%;
	margin: 0;

	#app {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	#popUpPizarraParaEscribir {
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		text-align: center;
	}

	/* Menu letras en miniatura */
	div.contenedorMenuLetras {
		flex-wrap: wrap;
		justify-content: space-evenly;
		padding: 1rem;
		display: flex;

		div.planetaMiniatura {
			aspect-ratio: 16 / 9;
			box-sizing: border-box;
			width: 12%;
		}

		.planetaMiniatura img {
			width: 100%;
		}
	}

	.contenedorMenu {
		height: 80%;
		padding: 1rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		overflow-y: scroll;
	}

	.miniatura {
		width: 25%;
		aspect-ratio: 16 / 9;
		padding: 2rem;
		box-sizing: border-box;
	}

	.miniatura img {
		width: 100%;
		border: 1px solid black;
	}

	/* Modal */
	.fondoModales {
		width: 100vw;
		height: 100vh;
		background-color: rgb(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
	}

	#closeWidget {
		position: absolute;
		top: -15px;
		right: -15px;
		/* z-index: 2; */
	}

	img#closeWidget {
		width: 33px;
		height: 33px;
	}

	.seleccionablePalabras {
		position: relative;
		width: 75%;
		aspect-ratio: 16 / 9;
		margin-bottom: 3rem;
	}

	/* Zona de la pizarra */
	.contenedorPizarraYBotonera {
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		width: 100%;
		aspect-ratio: 4/3;
		margin: auto;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-color: white;
	}

	.contenedorPizarra {
		height: 100%;
		width: 100%;
		position: absolute;
	}

	div.pizarra {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	canvas {
		touch-action: none;
	}

	div.botoneraPizarra {
		width: 100%;
		height: 75px;
		display: flex;
		position: absolute;
		bottom: -75px;
		justify-content: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 1);
	}

	div.boton {
		width: 50px;
		height: 50px;
		border-radius: 100%;
		margin-left: 1rem;
	}

	div.boton.seleccionado {
		transform: scale(1.2);
	}

	div.boton.goma {
		margin-left: 0;
		background-color: gray;
		background-image: url("../img/goma.png");
		background-size: 80%;
		background-repeat: no-repeat;
		background-position: center;
	}

	.papeleraPizarra {
		width: 2rem;
		position: absolute;
		bottom: 0.5rem;
		right: 0.5rem;
	}
}

/* Pantalla de los juegos */
#pantallasJuego {
	height: 100%;

	.contenedorJuegos {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-top: 2rem;

		a {
			text-decoration: none;
		}

		.tituloMemory {
			height: 2rem;
			width: 10rem;
			background-color: #6f559f;
			padding: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				color: white;
			}
		}
	}
}
